import React from 'react';
import { content as ContentPropType } from 'lib/CustomPropTypes';
import { PymFrame } from 'components/Pym/Frame';
import './styles.themed.scss';

/**
 * @param {{ data: string }} arg0
 * @returns {React.ReactElement}
 */
const DataVizLoader = ({ data }) => {
  const {
    computedValues: { url },
  } = data;

  const cleanedEmbedUrl = url?.replace(/&(childId|parentUrl)=[^&]+/g, '');

  return (
    <div className="data-viz-div">
      <PymFrame
        url={cleanedEmbedUrl}
        title="Data Visualization"
      />
    </div>
  );
};

DataVizLoader.propTypes = {
  data: ContentPropType.isRequired,
};

export default DataVizLoader;
